
export default {
  name: 'AtomListCircle',
  props: {
    title: {
      required: true,
      type: [String, Object],
      default: ''
    },
    text: {
      required: true,
      type: [String, Object],
      default: ''
    },
    column: {
      required: false,
      type: String,
      default: 'col-xs-6 col-3'
    }
  }
}


export default {
  name: 'AtomContact',
  props: {
    image: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String,
      default: ''
    },
    nr: {
      required: true,
      type: String,
      default: ''
    },
    email: {
      required: true,
      type: String,
      default: ''
    },
    position: {
      required: true,
      type: String,
      default: ''
    },
    urlLinkedin: {
      required: true,
      type: String,
      default: 'javascript:void(0)'
    }
  },
  computed: {
    client() {
      return config.CLIENT
    }
  },
  methods: {
    handleTracking() {
      if (
        this.$config.CLIENT === 'one' &&
        this.$config.RUNTIME_ENV === 'production' &&
        'lintrk' in window
      ) {
        window.lintrk('track', { conversion_id: 4403868 })
      }
    },
    resourceUrl(img) {
      if (img.includes('://')) {
        return img
      }
      return `${this.$store.getters.cdnBase}/${img}`
    }
  }
}

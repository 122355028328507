
export default {
  name: 'AtomCreator',
  props: {
    image: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String,
      default: ''
    },
    urlLinkedin: {
      required: true,
      type: String,
      default: 'javascript:void(0)'
    }
  },
  data() {
    return {
      isInView: false
    }
  },
  computed: {
    client() {
      return this.$config.CLIENT
    },
    cdnBase() {
      return this.$store.getters.cdnBase
    },
    assetUrl() {
      if (!this.image) return ''
      if (this.image.includes('://')) {
        return this.image
      }
      return `${this.cdnBase}/${this.image}`
    }
  },
  methods: {
    handleTracking() {
      if (
        this.$config.CLIENT === 'one' &&
        this.$config.RUNTIME_ENV === 'production' &&
        'lintrk' in window
      ) {
        window.lintrk('track', { conversion_id: 4403868 })
      }
    }
  }
}

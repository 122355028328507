
export default {
  name: 'AtomProjectSliderBackground',
  props: {
    image: {
      required: true,
      type: String,
      default: ''
    },
    video: {
      required: true,
      type: String,
      default: ''
    },
    activeBackground: {
      required: true,
      type: String,
      default: 'item-0'
    },
    backgroundId: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    isActive() {
      return this.$props.activeBackground === this.backgroundId
    },
    resourceUrl() {
      return `${this.$store.getters['base/cdnBase']}/${this.$props.image}`
    },
    resourceUrlVideo() {
      return `${this.$store.getters['base/cdnBase']}/${this.$props.video}`
    }
  }
}


export default {
  name: 'AtomPartner',
  props: {
    image: {
      required: true,
      type: String
    },
    link: {
      required: true,
      type: String,
      default: 'javascript:void(0)'
    }
  }
}
